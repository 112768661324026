<template>
    <el-row>
        <el-col :span="20" :offset="2" >
            <div class="toptitle">
                <h2>
                    {{ currentCapabilities.title }}

                </h2>

                <el-divider>
                    
                </el-divider>
            </div>
            <div v-html="currentCapabilities.content" class="newsdetails"></div>
            <div class="author">
                {{ currentCapabilities.author }}
            </div>
            <div class="blank"></div>
            <el-backtop :visibility-height="100" />

        </el-col>

    </el-row>

</template>
<script setup>
//watchEffect里面依赖的的值变化了钩子重新执行
import { onMounted, ref,watchEffect,onBeforeUnmount } from "vue";
import axios from "axios";
import { useRoute,useRouter } from "vue-router";
import moment from "moment";
import { StarFilled } from "@element-plus/icons-vue";


moment.locale("zh-cn");
const route = useRoute();
const router = useRouter();
const currentCapabilities = ref({});
const topNews = ref([]);

const stop  = watchEffect(async () => {
//   console.log(111,route.params.id)
  if(!route.params.id) return 
  const res1 = await axios.get(`/webapi/capabilities/list/${route.params.id}`);
//   const res2 = await axios.get(`/webapi/capabilities/toplist?limit=5`);
  //   console.log(res2.data.data)
  currentCapabilities.value = res1.data.data[0];
//   topNews.value = res2.data.data;
});
  
onBeforeUnmount(()=>{
    console.log(222,"onBeforeUnmount")
    stop()
})
// const whichTime = time => {
//   return moment(time).format("lll");
// };
// const handleChange = (id)=>{
//     // console.log(id)
//     router.push(`/capabilities/${id}`)
// }
</script>
<style scoped lang="scss">
.el-row{
    margin-top: 40px;
}
.time {
  font-size: 13px;
  color: gray;
}
.toptitle{
    text-align: center;
}
.newsdetails::v-deep {
    font-size:18px;
     text-indent:35px; 
     line-height:35px;

    img {
        width: 600px;
        display: block;
        margin: 0 auto;
    }

}
.author{
    float: right;
    padding-top: 15px;
    padding-right: 10%;
}
.blank{
    height: 100px;
}
</style>