<template>
    <div class="container">
        <div class="news-header"
            :style="{backgroundImage:`url(${require('@/assets/images/productshowcase.jpg')})`}">

        </div>


        <div class="Capabilitystaps">
            <el-tabs v-model="whichTab" class="demo-tabs" style="margin: 40px;"  type="card">
                <el-tab-pane :key="item.name" v-for="item in tablist" :label="item.label" :name="item.name" >
                    <!-- <el-row>
                        <el-col :span="1">

                        </el-col>
                        <el-col :span="22">
                            <div v-for="data in tabmanufacturing[item.name]" :key="data._id" class="manufacturingtext">
                                <div v-html="data.content" class="newsdetails"></div>
                            </div>
                        </el-col>
                        <el-col :span="1">

                        </el-col>
 
                    </el-row> -->
                    <el-row>
                        <div v-for="data in tabnews[item.name]" :key="data._id" style="padding-top: 30px;font-weight: 400;padding-left: 6%;padding-right: 6%;">
                            <el-card style="width: 540px" @click="handleChangepage(data._id)">
                                <div class="tab-image"
                                    :style="{ backgroundImage: `url(http://www.ynkcjx.com${data.cover})` }">
                                </div>
                                <template #footer>
                                    <div style="text-align: center;">
                                        <span >{{ data.title }}</span>
                                    </div>
                                    
                                </template>
                            </el-card>

                        </div>

                    </el-row>


                </el-tab-pane>

            </el-tabs>

            <el-backtop :visibility-height="100" />
            <el-row>
                <!-- <el-col :span="24">
                    <el-pagination v-model:current-page="currentPage" :page-size="10" layout="prev, pager, next"
                        :total="newlist.length" @current-change="handleCurrentChange" class="pagination"
                        style="margin-left: 35%;">
                    </el-pagination>
                </el-col> -->
                <el-col :span="24" style="height: 70px;">&nbsp;</el-col>

            </el-row>



        </div>
    </div>
</template>
<script setup>
import { Search } from "@element-plus/icons-vue";
import { ref,reactive, onMounted, computed } from "vue";
import axios from "axios";
import moment from 'moment'
import _ from "lodash";
import {useRouter} from 'vue-router'
import Pagination from './Pagination.vue';
const searchText = ref("")
const newlist = ref([]);
// const manufacturinglist = ref([]);
const visible = ref(false)

const whichTab =ref(1)

moment.locale("zh-cn");

// 给json数据定义一个list
// const contactList = reactive({
//     list: [],
//   });
// 当前页数
// const currentPage= ref(1);
//   // 当前页数变化时的回调函数
//   const handleCurrentChange = (val) => {
//     currentPage.value = val;
//   };

onMounted(async () => {
    try {
        var res = await axios.get("/webapi/products/list");
        // console.log(res.data)
        newlist.value = res.data.data;
        //contactList.value = res.data.data;
        //   var res1 = await axios.get("/webapi/manufacturing/list");
        //   manufacturinglist.value = res1.data.data;
    } catch (error) {
        console.error(error);
    }
  //console.log(_.groupBy(newlist.value,item=>item.category))
});
//查找关键字相关新闻
// const searchnewslist = computed(
//   () =>
//     searchText.value
//       ? newlist.value.filter(item => item.title.includes(searchText.value))
//       : []
// );

const topNewsList = computed(() => newlist.value.slice(0, 4));
//格式化时间
const whichTime = time=>{
    return moment(time).format("lll")
}//1铸造生产，2先进钣金集成生产，3大型框架件焊接制造，4精密机加，5热处理加工，
    //6表面处理、涂装，7高精度检测
const tablist =[
    {
        label:"烟机产品",
        name:1
    },
    {
        label:"物流产品",
        name:2
    },
    {
        label:"AGV产品",
        name:3
    },
    {
        label:"机场行李分拣",
        name:4
    },
    {
        label:"智慧停车",
        name:5
    },
    {
        label:"环保产品",
        name:6
    }
  
]
const tabnews = computed(() => _.groupBy(newlist.value, item => item.category));
// const tabmanufacturing = computed(() => _.groupBy(manufacturinglist.value, item => item.category));

const router = useRouter()
const handleChangepage = (id)=>{
     console.log(id)

    //router.push(`/capabilitys/${id}`)
     // 打开新窗口
     //console.log(id)
    //window.open(`http://www.ynkcjx.com/index1.html#/capabilitys/${id}`)//http://www.ynkcjx.com/index1.html#/
}

</script>
<style scoped lang="scss">

.container{
    position: relative;
}
.news-header{
    width: 100%;
    height: 380px;
    background-size: cover;
}
.search{
    position: absolute;
    top: 150px;
    width: 100%;
    text-align: center;
    .el-input{
        width: 50%;
    }
}
.search-item {
  height: 50px;
  line-height: 50px;
  &:hover {
    background: whitesmoke;
    color: #00428d;
  }
}

.topnews {
  margin: 80px;
  .image{
      width: 100%;
      height: 200px;
      background-size: cover;
  }
  .time{
      font-size:13px;
      color:gray;
  }
  .el-card{
    height: 300px;
  }
}
.tab-image{
    width: 500px;
    height: 300px;
    background-size: cover;
    float: left;
}
.Capabilitystaps{
    padding-left: 7%;
    padding-right: 7%;
}
.tab-time{
    font-size:13px;
    color:gray;
}
* {
  font-family: "OPPO Sans";
  }
  //页码
  .pagination{
    margin-left: 35%;
    margin-top: 30px;
  }
//   .manufacturingtext{

//   }
</style>