<template>
    <div class="navbar">
        <el-menu 
            :default-active="route.fullPath" 
            class="el-menu-demo" 
            mode="horizontal" 
            :router="true"
            >
            <el-menu-item index="0">
                <img style="width: 80%" src="/image/logo.png" alt="Element logo" />
            </el-menu-item>
            <div class="flex-grow" />
            <el-menu-item index="/">首页</el-menu-item>
            <el-menu-item index="/news">新闻资讯</el-menu-item>
            <el-menu-item index="/capabilitys">制造能力</el-menu-item>
            <el-menu-item index="/products">产品展示</el-menu-item>
            <el-sub-menu index="/companyprofile">
                <template #title>关于我们</template>
                <el-menu-item index="/companyprofile">公司简介</el-menu-item>
                <el-menu-item index="/corporateculture">企业文化</el-menu-item>
                <el-menu-item index="/qualification">企业资质</el-menu-item>
                <el-menu-item index="/honors">公司荣誉</el-menu-item>
                <el-menu-item index="/videos">视频中心</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="/recruitment">
                <template #title>诚聘英才</template>
                <el-menu-item index="/recruitment">招聘公告</el-menu-item>
                <el-menu-item index="/recruitmentschool">校园招聘</el-menu-item>
                <el-menu-item index="/recruitmentsocial">社会招聘</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="/contact">
                <template #title>联系我们</template>
                <el-menu-item index="/contact">联系我们</el-menu-item>
                <el-menu-item index="/Massage">在线留言</el-menu-item>
            </el-sub-menu>
            <el-menu-item index="" @click="handleClick">登录</el-menu-item>
        </el-menu>
    </div>
</template>

<script setup>
import {useRoute} from 'vue-router'
const route = useRoute()

const handleClick = ()=>{
    window.location = "http://www.ynkcjx.com/admin"
}
</script>
<style scoped lang="scss">
.navbar{
    position: sticky;
    top:0px;
    z-index:1000;
    margin-top: 20px;
}
.flex-grow {
  flex-grow: 1;
}
</style>