<link rel="stylesheet" type="text/css" href="Assets/css/thems.css"></link>
<template>
  <div>
    <div class="news-header" :style="{ backgroundImage: `url(${require('@/assets/images/about-us.jpg')})` }">
    </div>
    <!-- <div class="Breadcrumb">
            <el-breadcrumb :separator-icon="ArrowRight">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>关于我们</el-breadcrumb-item>
                 <el-breadcrumb-item>promotion list</el-breadcrumb-item>
            <el-breadcrumb-item>promotion detail</el-breadcrumb-item> 
            </el-breadcrumb>
            <el-divider style="margin-top: 10px;"/>
        </div> -->
    <div>
      <div>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
          @select="handleSelect" :router="true">
          <!-- <el-menu-item class="el-icon-location">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-menu-item> -->
          <el-menu-item disabled="true"></el-menu-item>
          <el-menu-item index="/companyprofile" class="menu">公司简介</el-menu-item>
          <el-menu-item index="/corporateculture" class="menu">企业文化</el-menu-item>
          <el-menu-item index="/qualification" class="menu">企业资质</el-menu-item>
          <el-menu-item index="/honors" class="menu1">公司荣誉</el-menu-item>
          <el-menu-item index="/videos" class="menu">视频中心</el-menu-item>

        </el-menu>
        <div class="Breadcrumbbround">
          <div class="Breadcrumb">
            <el-breadcrumb :separator-icon="ArrowRight">
              <el-breadcrumb-item :to="{ path: '/' }">
                <el-icon style="color: #00428d;font-size: larger;">
                  <HomeFilled />
                </el-icon>
              </el-breadcrumb-item>
              <el-breadcrumb-item>关于我们</el-breadcrumb-item>
              <el-breadcrumb-item>公司荣誉</el-breadcrumb-item>
            </el-breadcrumb>
          </div>

        </div>
        <div class="scd_r">
          <!-- <div class="r_title">
            公司荣誉<span>/<em>Company Honors</em></span>
          </div> -->
          <div class="demo-image__preview">
            <el-row style="height: 60px;">&nbsp;</el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 80%" :src="require('../assets/honor/知识产权优势企业.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList1" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 80%" :src="require('../assets/honor/国家知识产权优势企业.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList2" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 80%" :src="require('../assets/honor/数字化转型.jpg')" :zoom-rate="1.2" :max-scale="7"
                  :min-scale="0.2" :preview-src-list="srcList3" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 80%" :src="require('../assets/honor/数字化转型2.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList4" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 80%" :src="require('../assets/honor/QC2.jpg')" :zoom-rate="1.2" :max-scale="7"
                  :min-scale="0.2" :preview-src-list="srcList5" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 80%" :src="require('../assets/honor/QC.jpg')" :zoom-rate="1.2" :max-scale="7"
                  :min-scale="0.2" :preview-src-list="srcList6" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 80%" :src="require('../assets/honor/工会女职工.jpg')" :zoom-rate="1.2" :max-scale="7"
                  :min-scale="0.2" :preview-src-list="srcList7" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 80%" :src="require('../assets/honor/常务理事.jpg')" :zoom-rate="1.2" :max-scale="7"
                  :min-scale="0.2" :preview-src-list="srcList8" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 80%" :src="require('../assets/honor/劳动保障一级.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList9" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 80%" :src="require('../assets/honor/先进生产单位.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList10" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 80%" :src="require('../assets/honor/先进生产单位2.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList11" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 80%" :src="require('../assets/honor/知识产权试点.jpg')" :zoom-rate="1.2"
                  :max-scale="7" :min-scale="0.2" :preview-src-list="srcList12" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="10">

                <el-image style="width: 80%" :src="require('../assets/honor/物流.jpg')" :zoom-rate="1.2" :max-scale="7"
                  :min-scale="0.2" :preview-src-list="srcList13" :initial-index="4" fit="cover" />
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="10">
                <el-image style="width: 80%" :src="require('../assets/honor/安康杯.jpg')" :zoom-rate="1.2" :max-scale="7"
                  :min-scale="0.2" :preview-src-list="srcList14" :initial-index="4" fit="cover" />
              </el-col>
            </el-row>
            <el-row>
              <el-image style="width: 50%;padding-left: 20%;" :src="require('../assets/honor/金牌供应商.jpg')"
                :zoom-rate="1.2" :max-scale="7" :min-scale="0.2" :preview-src-list="srcList15" :initial-index="4"
                fit="cover" />
            </el-row>
            <el-row>
              <el-image style="width: 100%" :src="require('../assets/honor/奖牌荣誉.jpg')" :zoom-rate="1.2" :max-scale="7"
                :min-scale="0.2" :preview-src-list="srcList16" :initial-index="4" fit="cover" />
            </el-row>



          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { ArrowRight,Calendar,HomeFilled } from '@element-plus/icons-vue';
import {useRouter} from 'vue-router'
const router = useRouter()
// const newsgateToCulture=()=>{
//   router.push(`/corporateculture`)
// }
// const newsgateToProfile=()=>{
//   router.push(`/companyprofile`)
// }
// const newsgateToQualification=()=>{
//   router.push(`/qualification`)
// }
// const newsgateToVideos=()=>{
//   router.push(`/videos`)
// }
const srcList1 = [
  require('../assets/honor/知识产权优势企业.jpg'),
];
const srcList2 = [
  require('../assets/honor/国家知识产权优势企业.jpg'),
]
const srcList3 = [
  require('../assets/honor/数字化转型.jpg'),
]
const srcList4 = [
  require('../assets/honor/数字化转型2.jpg'),
]
const srcList5 = [
  require('../assets/honor/QC2.jpg'),
]
const srcList6 = [
  require('../assets/honor/QC.jpg'),
]
const srcList7 = [
  require('../assets/honor/工会女职工.jpg'),
]
const srcList8 = [
  require('../assets/honor/常务理事.jpg'),
]
const srcList9 = [
  require('../assets/honor/劳动保障一级.jpg'),
]
const srcList10 = [
  require('../assets/honor/先进生产单位.jpg'),
]
const srcList11 = [
  require('../assets/honor/先进生产单位2.jpg'),
]
const srcList12 = [
  require('../assets/honor/知识产权试点.jpg'),
]
const srcList13 = [
  require('../assets/honor/物流.jpg'),
]
const srcList14 = [
  require('../assets/honor/安康杯.jpg'),
]
const srcList15 = [
  require('../assets/honor/金牌供应商.jpg'),
]
const srcList16 = [
  require('../assets/honor/奖牌荣誉.jpg'),
]
</script>


<style scoped lang="scss">
//子菜单
.el-menu{
  border-radius: 5px!important;
  border-block-color: #f4f4f4;
  height: 80px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.menu {
  width: 190px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  position: relative; /* 为了使::after伪元素正确显示 */
}
.menu::after {
  content: '';
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%; /* 边框宽度可以根据需要调整 */
  height: 20%; /* 高度与容器相同 */
  border-right: 2px solid rgb(222, 222, 222); /* 边框样式，可以根据需要调整 */
}
.menu1{
  width: 190px;
  background-image: linear-gradient(to bottom, #00428d, #00428d);
  color: #fff !important;
}
.el-submenu__title:hover,
.el-submenu.is-active,
.el-menu-item.is-active {
    background-image: linear-gradient(to bottom, #00428d, #00428d);
    background-color: transparent;
    border-bottom-color: #00428d !important;
    border-bottom: 2px solid #00428d;
    color: #fff !important;
}
.el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
      background-image: linear-gradient(to bottom, #00428d, #00428d);
      border-bottom-color: #00428d !important;
      color: #fff !important;
    }

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 0%;
}
//面包屑
.Breadcrumbbround{
    padding-top: 20px;
    height: 35px;
    margin: 0 auto;
    width: 80%;
    background-color: #f7f7f7;
    margin-top: 30px;
    border-radius: 50px / 50px; /* 水平半径和垂直半径 */
}
.Breadcrumb{
    
    margin-left: 2%;
}
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #00428d;
  font-size: 32px;
  font-weight: 600;
}
.demo-tabs .custom-tabs-label .el-icon {
  vertical-align: middle;
  color: #00428d;
}
.demo-tabs .custom-tabs-label span {
  vertical-align: middle;
  margin-left: 4px;
  color: #00428d;
}
::v-deep .el-tabs__item {
    color:green;
    opacity: 0.5;
}
.news-header {
    width: 100%;
    height: 300px;
    background-size: cover;
}

* {
  font-family: "OPPO Sans";
  }
  .company{
    width: 80%;  
  }
  
  .company img{
	position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%,0%);
}

//
.company-pic{   
    width: 100%;
    padding-left: 40%;
}
.scd_r {
    width: 75%;
    margin: 0 auto;
}

.scd_r .r_title {
    width: 100%;
    text-align: center;
    padding-top: 55px;
    height: 45px;
    line-height: 45px;
    
    font-size: 30px;
    color: #000000;
}

.scd_r .r_title span {
    color: #999999;
}

.scd_r .r_title span em {
    font-size: 18px;
}

.scd_r .scd_m {
    width: 100%;
    padding: 28px 0;
    font-size: 14px;
    color: #999999;
    line-height: 215%;
}

.scd {
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 20px;
}
.scd .scd_l{
	width: 220px;
	height: 700px;
	float: left;
	background: url(../assets/picture/bg_a1.png) no-repeat;
}

.scd_l .name{ width:100%; text-align:center; padding:30px 0;}
.scd_l .s_nav{
	width: 100%;
	text-align: left;
}
.scd_l .s_nav li{
	width: 190px;
	height: 42px;
	background: url(../assets/picture/line1.jpg) no-repeat left bottom;
	float: right;
	margin-right: 1px;
}
.scd_l .s_nav li a{
	display: block;
	width: 190px;
	height: 40px;
	padding-right: 0px;
	text-align: left;
	line-height: 40px;
	font-size: 16px;
	color: #552F00;
}
.scd_l .s_nav li.now a,
.scd_l .s_nav li:hover a {
    background: #00428d;
    color: #FFF;
}

.scd_l .s_nav li a span {
    display: inline-block;
    *display: inline;
    zoom: 1;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    background: url(../assets/picture/icon3.png) no-repeat left center;
}

.scd_l .s_nav li.now a span,
.scd_l .s_nav li:hover a span {
    color: #FFF;
    background: url(../assets/picture/icon2.png) no-repeat left center;
}
.p{
    text-indent: 2em;
}
</style>