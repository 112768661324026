<link rel="stylesheet" type="text/css" href="Assets/css/thems.css"></link>
<template>
    <div>
        <div class="news-header" :style="{ backgroundImage: `url(${require('@/assets/images/about-us.jpg')})` }">
        </div>
        <!-- <div class="Breadcrumb">
            <el-breadcrumb :separator-icon="ArrowRight">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>关于我们</el-breadcrumb-item>
                 <el-breadcrumb-item>promotion list</el-breadcrumb-item>
            <el-breadcrumb-item>promotion detail</el-breadcrumb-item> 
            </el-breadcrumb>
            <el-divider style="margin-top: 10px;"/>
        </div> -->
        <div>
            <div>
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
          @select="handleSelect" :router="true">
          <!-- <el-menu-item class="el-icon-location">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-menu-item> -->
          <el-menu-item disabled="true"></el-menu-item>
          <el-menu-item index="/companyprofile" class="menu">公司简介</el-menu-item>
          <el-menu-item index="/corporateculture" class="menu1">企业文化</el-menu-item>
          <el-menu-item index="/qualification" class="menu">企业资质</el-menu-item>
          <el-menu-item index="/honors" class="menu">公司荣誉</el-menu-item>
          <el-menu-item index="/videos" class="menu">视频中心</el-menu-item>

        </el-menu>
        <div class="Breadcrumbbround">
                <div class="Breadcrumb">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>关于我们</el-breadcrumb-item>
                        <el-breadcrumb-item>企业文化</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

            </div>

                <div class="scd_r">
                    <!-- <div class="r_title">
              企业文化<span>/<em>Corporate Culture</em></span>
            </div> -->
                    <div class="tagline">
                        <el-row>
                            <el-col :span="24">
                                <img src="../assets/picture/和谐共生绿色制造.jpg" width="600px" alt="" />
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="11">
                                <el-row>
                                    <p class="tagline">和谐共生</p>
                                    <img src="../assets/picture/line.jpg" width="600px" alt="" />
                                </el-row>
                                <el-row>
                                    <div class="tagline-txt">
                                        <p>携手股东与员工，创新发展模式，创造优异业绩，推动公司转型升级，实现共建共享。</p>
                                        <p>携手客户与伙伴，升级产品服务，挖掘行业价值，促进产业更新迭代，实现共生共融</p>
                                        <p>携手社会与环境，坚持绿色发展，维护和谐稳定，回应时代使命召唤，实现共管共治。</p>
                                        <p>企业就像一棵大树，逆风而生风必摧之，顺风而长风必著之。昆船机械离不开行业这片沃土，更离不开中国市场这片辽阔大海。一棵树的生长是缓慢的，当她与阳光、空气、雨水、土壤形成共生、与鸟儿相互为伴，成为生态大家庭的一员，生命才能充满生机和无限希望。
                                        </p>
                                        <p>昆船机械通过接入工业互联大家庭，不断汲取养分进行产业升级，华丽转型，链接行业生态圈，寻找到更多志同道合的伙伴，资源共享，携手向智能制造的方向突进。</p>
                                    </div>

                                </el-row>
                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="12">
                                <div style="padding-top: 100px;">
                                    <img src="../assets/picture/共生.jpg" width="100%" alt="" />
                                </div>

                            </el-col>
                        </el-row>
                        <el-row>&nbsp;</el-row>
                        <el-row>
                            <el-col :span="10">
                                <div style="padding-top: 10px;">
                                    <img src="../assets/picture/绿色.jpg" width="95%" alt="" />
                                </div>

                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="13">
                                <div>
                                    <el-row>
                                        <p class="tagline">绿色</p>
                                        <img src="../assets/picture/line.jpg" width="600px" alt="" />
                                    </el-row>
                                    <el-row>
                                        <div class="tagline-txt">
                                            <p>我们致力于让绿色发展理念深入人心，更加注重生态安全、低碳环保、清洁环保和节约集约，广泛应用绿色低碳新技术，持续加大节能、减排、降碳力度，实现生产能耗、物料消耗及污染物排放量持续降低，全面建设资源节约型、环境友好型的可持续发展企业。
                                            </p>
                                            <p>绿色是昆船机械一切经营行为的原则和底线，智能制造是我们的方法和手段。</p>

                                        </div>

                                    </el-row>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row><el-divider /></el-row>
                        <el-row style="height: 60px;">&nbsp;</el-row>
                        <el-row>
                            <el-col :span="11">
                                <el-row>
                                    <img src="../assets/picture/品质改变世界.jpg" width="337.5px" alt="" />
                                </el-row>
                                <el-row>
                                    <div class="tagline-txt" style="padding-top: 20px;">
                                        <p>
                                            对于以装备制造业为主的昆船机械来说，公司致力于稳定、可靠、高效的机电装备研制，成为团结、创新、高效、有活力有担当的现代化制造型企业，满足客户需求。在此过程中，不断追求和创造出品质卓越的产品和服务，并以此来促使行业升级换代至世界一流水准，引领行业发展，进而广泛影响人们的生产、工作或生活方式，为人类文明的进步做出贡献。
                                        </p>
                                    </div>
                                </el-row>
                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="12">
                                <div style="">
                                    <img src="../assets/picture/品质.jpg" width="100%" alt="" />
                                </div>
                            </el-col>
                        </el-row>
                        <el-row><el-divider /></el-row>
                        <el-row style="height: 60px;">&nbsp;</el-row>
                        <el-row>
                            <el-col :span="12">
                                <div style="">
                                    <img src="../assets/picture/客户.jpg" width="100%" alt="" />
                                </div>
                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="11">
                                <el-row>
                                    <img src="../assets/picture/创造客户价值.jpg" width="337.5px" alt="" />
                                </el-row>
                                <el-row>
                                    <div class="tagline-txt" style="padding-top: 20px;">
                                        <p>
                                            聚集客户的挑战和压力，提供有竞争力的装备解决方案和服务，持续为客户创造最大价值。
                                        </p>
                                    </div>
                                </el-row>
                            </el-col>

                        </el-row>
                        <el-row><el-divider /></el-row>
                        <el-row style="height: 60px;">&nbsp;</el-row>
                        <el-row>
                            <el-col :span="24">
                                <img src="../assets/picture/以客户为中心.jpg" width="600px" alt="" />
                            </el-col>
                            <div class="tagline-txt">
                                <p>
                                    客户是谁与客户想什么，是我们“以客户为中心”的认识基础，没有这个基础，我们就找不到为客户服务的正确起点，我们就不知道为客户做什么和怎样做，甚至找不到客户在哪里。
                                    昆船机械的终端客户是一个个企业，一个个经济组织。他们想更好地完成工作，更快地完成工作，更轻松地完成工作，从而创造更高的利润。为此，我们需要为客户提供质量高，性能好，速度快，寿命长，维修方便，无故障时间长的产品，同时提供最完美的服务，这样才会有越来越多的客户选择昆船机械的产品。也就是说，客户想什么，作为“以客户为中心”的昆船机械人，就应该想什么;客户怎样想，昆船机械人就怎样想，客户可能想什么，昆船机械人就应该先想什么;甚至客户没有想到的，我们都要替客户想到。为客户提供超出预想之外的更优质的产品和服务，客户的满意度将会大大提升。
                                </p>
                                <p>
                                    那么“中心”又意味着什么呢?在昆船机械，“以客户为中心”是指从产品设计、采购、生产、销售、服务等整个系统;指从公司的战略、管理、制度、规范等整个系统;指每个昆船机械人的思想、观念、认识、思维、方法等整个系统;公司内和公司外的所有存在都必须“以客户为中心”。
                                </p>
                            </div>
                        </el-row>
                        <el-row><el-divider /></el-row>
                        <el-row style="height: 60px;">&nbsp;</el-row>
                        <el-row>
                            <el-col :span="24">
                                <img src="../assets/picture/企业精神.jpg" width="600px" alt="" />
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="11">
                                <el-row>
                                    <p class="tagline">诚信</p>
                                    <img src="../assets/picture/line.jpg" width="600px" alt="" />
                                </el-row>
                                <el-row>
                                    <div class="tagline-txt">
                                        <p>公司的经营行为一直与正义、道德、良知和承诺结合在一起的。我们公司诚信的价值链是公司对员工的诚信，员工对公司的诚信，最终表现为公司对客户与社会的诚信。归根结底，是源于我们每名员工认同公司的核心价值观，恪守诚信的承诺，靠的是辛勤的付出，而非不劳而获的投机和讨价还价，方能产生诚信的行为，建立诚信的品格。
                                        </p>
                                        <p>诚信是一个民族、一个国家、一个企业、一个人的立命根本。不讲诚信，人人对你惟恐避之不及，哪还谈什么喜爱和接受。客户回避你，供应商回避你，同事和朋友回避你，甚至亲戚和家人都回避你，整个社会都会离你而去，使你无法立足。人与人之间，企业与企业之间互不信任的严重后果就是行为短期化。一个人、一个企业、一个民族，如果没有长远目标，而只有短期行为，则这个人、企业、甚至民族就没有前途和希望了。诚实守信是一个人立足社会的基础，也是一个人应有的基本道德品质。
                                        </p>
                                    </div>

                                </el-row>
                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="12">
                                <div style="padding-top: 120px;">
                                    <img src="../assets/picture/诚信.jpg" width="100%" alt="" />
                                </div>

                            </el-col>
                        </el-row>
                        <el-row>&nbsp;</el-row>
                        <el-row>
                            <el-col :span="10">
                                <div style="padding-top: 10px;">
                                    <img src="../assets/picture/精神-品质.jpg" width="95%" alt="" />
                                </div>

                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="13">
                                <div>
                                    <el-row>
                                        <p class="tagline">品质</p>
                                        <img src="../assets/picture/line.jpg" width="600px" alt="" />
                                    </el-row>
                                    <el-row>
                                        <div class="tagline-txt">
                                            <p>
                                                “品质”有三个层面的意义，一是个人品质，这一点以诚信为核心;二是产品和服务品质;三是企业品质，具有更为综合的内涵，涵盖质量、管理、技术和人才品质。
                                            </p>


                                        </div>

                                    </el-row>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="11">
                                <el-row>
                                    <p class="tagline">温度</p>
                                    <img src="../assets/picture/line.jpg" width="600px" alt="" />
                                </el-row>
                                <el-row>
                                    <div class="tagline-txt">
                                        <p>家一样的温暖、舒适的企业氛围。温度是真诚、热心、关怀，及可靠的支持和行动，区别于冷漠、机械、古板的流水线式管理，我们强调严格而不乏人情味，这是昆船机械赋予员工、客户和社会的温度。这种温度蕴藏于昆船机械的日常工作中，蕴藏于昆船机械人的行为中。团结紧张、严肃活泼是昆船机械温度的集中体现。
                                        </p>
                                        <p>我们有牵引与激励机制，公司建立以价值创造者为本的内部分配机制，奉献者定当得到合理的回报，决不让雷锋吃亏，我们会给予价值创造者春天般的温暖;公司也有约束与淘汰机制，对不求进取、思想惰怠、不创造价值者给予严冬般的寒冷。
                                        </p>
                                    </div>

                                </el-row>
                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="12">
                                <div style="padding-top: 70px;">
                                    <img src="../assets/picture/温暖.jpg" width="95%" alt="" />
                                </div>

                            </el-col>
                        </el-row>
                        <el-row>&nbsp;</el-row>
                        <el-row>
                            <el-col :span="10">
                                <div style="padding-top: 10px;">
                                    <img src="../assets/picture/责任.jpg" width="95%" alt="" />
                                </div>

                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="13">
                                <div>
                                    <el-row>
                                        <p class="tagline">责任</p>
                                        <img src="../assets/picture/line.jpg" width="600px" alt="" />
                                    </el-row>
                                    <el-row>
                                        <div class="tagline-txt">
                                            <p>
                                                具有责任心和服从管理(执行力)的员工是公司最大的财富。尊重能力、尊重知识、尊重劳动、团结协作和不迁就不作为的员工，是我们企业高质量发展的内在要求。制造的方向突进。
                                            </p>


                                        </div>

                                    </el-row>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="11">
                                <el-row>
                                    <p class="tagline">突破</p>
                                    <img src="../assets/picture/line.jpg" width="600px" alt="" />
                                </el-row>
                                <el-row>
                                    <div class="tagline-txt">
                                        <p>公司的追求是在机械制造领域实现客户的需求，并依靠脚踏实地和勇于创新的艰苦追求，使我们成为国内领先的装备制造先进企业。广泛吸收世界装备制造领域的最新研究成果，虚心向国内外优秀企业学习，在独立自主的基础上，不断研发装备制造核心技术，用我们卓越的产品自立于机械制造行业中。
                                        </p>
                                    </div>

                                </el-row>
                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="12">
                                <div style="padding-top: 10px;">
                                    <img src="../assets/picture/突破.jpg" width="95%" alt="" />
                                </div>

                            </el-col>
                        </el-row>
                        <el-row><el-divider /></el-row>
                        <el-row style="height: 60px;">&nbsp;</el-row>
                        <el-row>
                            <el-col :span="24">
                                <img src="../assets/picture/紧盯.jpg" width="600px" alt="" />
                            </el-col>
                            <div class="tagline-txt">
                                <p>
                                    1. 满足客户需求是公司存在的唯一基础，服务国家是公司发展的根基和进步动力。
                                </p>
                                <p>
                                    2.优质、高效、低成本服务好客户，不断提升客户竞争力。
                                </p>
                                <p>
                                    3. 持续优化管理，不断提高效率，确保高效有序交付
                                </p>
                                <p>
                                    4.加强同业间联动发展，持续提升产业链生存能力。
                                </p>
                            </div>
                        </el-row>
                        <el-row style="height: 60px;">&nbsp;</el-row>



                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { ArrowRight,Calendar,HomeFilled } from '@element-plus/icons-vue';
import {useRouter} from 'vue-router'
const router = useRouter()
const newsgateToProfile=()=>{
  router.push(`/companyprofile`)
}
const newsgateToQualification=()=>{
  router.push(`/qualification`)
}
const newsgateToHonors=()=>{
  router.push(`/Honors`)
}
const newsgateToVideos=()=>{
  router.push(`/videos`)
}
</script>

<style scoped lang="scss">
//子菜单
.el-menu{
  border-radius: 5px!important;
  border-block-color: #f4f4f4;
  height: 80px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.menu {
  width: 190px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  position: relative; /* 为了使::after伪元素正确显示 */
}
.menu::after {
  content: '';
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%; /* 边框宽度可以根据需要调整 */
  height: 20%; /* 高度与容器相同 */
  border-right: 2px solid rgb(222, 222, 222); /* 边框样式，可以根据需要调整 */
}
.menu1{
  width: 190px;
  background-image: linear-gradient(to bottom, #00428d, #00428d);
  color: #fff !important;
}
.el-submenu__title:hover,
.el-submenu.is-active,
.el-menu-item.is-active {
    background-image: linear-gradient(to bottom, #00428d, #00428d);
    background-color: transparent;
    border-bottom-color: #00428d !important;
    border-bottom: 2px solid #00428d;
    color: #fff !important;
}
.el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
      background-image: linear-gradient(to bottom, #00428d, #00428d);
      border-bottom-color: #00428d !important;
      color: #fff !important;
    }

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 0%;
}
//面包屑
.Breadcrumbbround{
    padding-top: 20px;
    height: 35px;
    margin: 0 auto;
    width: 80%;
    background-color: #f7f7f7;
    margin-top: 30px;
    border-radius: 50px / 50px; /* 水平半径和垂直半径 */
}
.Breadcrumb{
    
    margin-left: 2%;
}
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #00428d;
  font-size: 32px;
  font-weight: 600;
}
.demo-tabs .custom-tabs-label .el-icon {
  vertical-align: middle;
  color: #00428d;
}
.demo-tabs .custom-tabs-label span {
  vertical-align: middle;
  margin-left: 4px;
  color: #00428d;
}
::v-deep .el-tabs__item {
    color:green;
    opacity: 0.5;
}
.news-header {
    width: 100%;
    height: 300px;
    background-size: cover;
}

* {
  font-family: "OPPO Sans";
  }
  .company{
    width: 80%;  
  }
  
  .company img{
	position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%,0%);
}

//
.company-pic{   
    width: 100%;
    padding-left: 40%;
}
.scd_r {
    width: 75%;
    margin: 0 auto;
}

.scd_r .r_title {
    width: 100%;
    text-align: center;
    padding-top: 55px;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #ccc;
    font-size: 30px;
    color: #000000;
}

.scd_r .r_title span {
    color: #999999;
}

.scd_r .r_title span em {
    font-size: 18px;
}

.scd_r .scd_m {
    width: 100%;
    padding: 28px 0;
    font-size: 14px;
    color: #999999;
    line-height: 215%;
}

.scd {
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 20px;
}
.scd .scd_l{
	width: 220px;
	height: 700px;
	float: left;
	background: url(../assets/picture/bg_a1.png) no-repeat;
}

.scd_l .name{ width:100%; text-align:center; padding:30px 0;}
.scd_l .s_nav{
	width: 100%;
	text-align: left;
}
.scd_l .s_nav li{
	width: 190px;
	height: 42px;
	background: url(../assets/picture/line1.jpg) no-repeat left bottom;
	float: right;
	margin-right: 1px;
}
.scd_l .s_nav li a{
	display: block;
	width: 190px;
	height: 40px;
	padding-right: 0px;
	text-align: left;
	line-height: 40px;
	font-size: 16px;
	color: #552F00;
}
.scd_l .s_nav li.now a,
.scd_l .s_nav li:hover a {
    background: #00428d;
    color: #FFF;
}

.scd_l .s_nav li a span {
    display: inline-block;
    *display: inline;
    zoom: 1;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    background: url(../assets/picture/icon3.png) no-repeat left center;
}

.scd_l .s_nav li.now a span,
.scd_l .s_nav li:hover a span {
    color: #FFF;
    background: url(../assets/picture/icon2.png) no-repeat left center;
}
.p{
    text-indent: 2em;
}
//宣传语
.tagline{
    padding-top: 40px;

}
//小标题
.tagline{
   font-size: large; 
}
.tagline-txt p{
    font-size: 15px;
    text-indent: 2em;
}
</style>