<link rel="stylesheet" type="text/css" href="Assets/css/thems.css">
</link>
<template>
    <div>
        <div class="news-header" :style="{ backgroundImage: `url(${require('@/assets/images/Recruitment.jpg')})` }">
        </div>
        <div>
            <div>
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
                    @select="handleSelect" :router="true">
                    <!-- <el-menu-item class="el-icon-location">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-menu-item> -->
                    <el-menu-item disabled="true"></el-menu-item>
                    <el-menu-item index="/recruitment" class="menu">招聘公告</el-menu-item>
                    <el-menu-item index="/recruitmentschool" class="menu">校园招聘</el-menu-item>
                    <el-menu-item index="/recruitmentsocial" class="menu1">社会招聘</el-menu-item>

                </el-menu>
                <div class="Breadcrumbbround">
                    <div class="Breadcrumb">
                        <el-breadcrumb :separator-icon="ArrowRight">
                            <el-breadcrumb-item :to="{ path: '/' }">
                                <el-icon style="color: #00428d;font-size: larger;">
                                    <HomeFilled />
                                </el-icon>
                            </el-breadcrumb-item>
                            <el-breadcrumb-item>诚聘英才</el-breadcrumb-item>
                            <el-breadcrumb-item>社会招聘</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>

                </div>

                <div class="scd_r">
                    <!-- <div class="r_title">
            公司简介<span>/<em>Company profile</em></span>
          </div> -->
                    <div class="scd_m">
                        <div class="recruitment">
                            <el-table :data="pagedData" style="width: 100%;font-size: large;"
                                :header-cell-style="{ 'text-align': 'center', 'background': '#00428d', 'color': '#fff' }"
                                :cell-style="{ 'text-align': 'center','padding': '20px' }"  stripe
                                :row-style="{height: '0'}" >
                                <el-table-column prop="post" label="招聘岗位" width="220"/>
                                <!-- <el-table-column label="类别">
                                    <template #default="scope">
                                        {{ categoryFormat(scope.row.category) }}
                                    </template>
                                </el-table-column> -->
                                <el-table-column prop="number" label="招聘人数" width="120"/>
                                <el-table-column prop="educational" label="岗位要求" width="360" />
                                <el-table-column prop="salary" label="薪资待遇" />
                                <el-table-column prop="location" label="工作地点" />

                                <!-- <el-table-column prop="author" label="作者" />

                <el-table-column label="是否发布">
                    <template #default="scope">
                        <el-switch v-model="scope.row.isPublish" :active-value="1" :inactive-value="0"
                            @change="handleSwitchChange(scope.row)" />
                    </template>
                </el-table-column> -->

                                <el-table-column label="详情">
                                    <template #default="scope">
                                        <!-- <el-button circle :icon="View" type="success"
                                            @click="handlePreview(scope.row)"></el-button> -->
                                        <el-button circle :icon="Plus" @click="handleEdit(scope.row)"></el-button>
                                        <!-- <el-popconfirm title="你确定要删除吗？" confirm-button-text="确定" cancel-button-text="取消"
                                            @confirm="handleDelete(scope.row)">
                                            <template #reference>
                                                <el-button circle :icon="Delete" type="danger"></el-button>
                                            </template>
                                        </el-popconfirm> -->
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-row style="margin-top:30px">
                                <el-col :span="10">

                                </el-col>
                                <el-col :span="14">
                                  <el-pagination v-model:current-page="currentPage" :page-size="10"
                                        layout="prev, pager, next" :total="contactList.list.length"
                                        @current-change="handleCurrentChange">
                                    </el-pagination>
                                </el-col>

                            </el-row>


                            <p>&nbsp;</p>
                            <!--<table width="160" border="0">
                <tr>
                  <img src="Assets/images/zz.jpg" width="150"  >
                  </tr>
                </table>
              <p>&nbsp;</p>
              <p><img src="Assets/images/企业架构图.png" width="1000" ></p>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>


import {ref,reactive,onMounted, computed} from 'vue'
import axios from 'axios'
import {Star,Edit,Delete,View,StarFilled,ArrowRight, Calendar, HomeFilled,Plus} from '@element-plus/icons-vue'
import {useRouter} from 'vue-router'
const router = useRouter()
const tableData = ref([])
const previewData = ref({});
const dialogVisible = ref(false);

// 给json数据定义一个list
const contactList = reactive({
    list: [],
  });
// 当前页数
const currentPage= ref(1);
  // 当前页数变化时的回调函数
  const handleCurrentChange = (val) => {
    currentPage.value = val;
  };
// 当前页数变化时的回调函数
const pagedData = computed(() => {
    let start = (currentPage.value - 1) * 10;
    const end = start + 10;
    const res = contactList.list.slice(start, end);
    for (let data of res) {
      data.virtualId = ++start;
    }
    return res;
  });

onMounted(() => {
    getTableData()
})

const getTableData =async ()=>{
  try {
    const res = await axios.get(`/webapi/post/listsocial`)
    //    console.log(res.data.data)
    tableData.value = res.data.data;
    contactList.list = res.data.data;
  } catch (error) {
    console.error(error);
  }
}
//格式化分类信息
const categoryFormat = (category)=>{
    const arr=["校园招聘","社会招聘"]
    return arr[category-1]
}
//查看详情回调
const handleEdit = (item)=>{
    //console.log(item._id)

    router.push(`/recruitmentsocial/${item._id}`)
}
</script>

<style scoped lang="scss">
//子菜单
.el-menu{
  border-radius: 5px!important;
  border-block-color: #f4f4f4;
  height: 80px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.menu {
  width: 190px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  position: relative; /* 为了使::after伪元素正确显示 */
}
.menu::after {
  content: '';
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%; /* 边框宽度可以根据需要调整 */
  height: 20%; /* 高度与容器相同 */
  border-right: 2px solid rgb(222, 222, 222); /* 边框样式，可以根据需要调整 */
}
.menu1{
  width: 190px;
  background-image: linear-gradient(to bottom, #00428d, #00428d);
  color: #fff !important;
}
.el-submenu__title:hover,
.el-submenu.is-active,
.el-menu-item.is-active {
    background-image: linear-gradient(to bottom, #00428d, #00428d);
    background-color: transparent;
    border-bottom-color: #00428d !important;
    border-bottom: 2px solid #00428d;
    color: #fff !important;
}
.el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
      background-image: linear-gradient(to bottom, #00428d, #00428d);
      border-bottom-color: #00428d !important;
      color: #fff !important;
    }

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 0%;
}
//面包屑
.Breadcrumbbround{
    padding-top: 20px;
    height: 35px;
    margin: 0 auto;
    width: 80%;
    background-color: #f7f7f7;
    margin-top: 30px;
    border-radius: 50px / 50px; /* 水平半径和垂直半径 */
}
.Breadcrumb{
    
    margin-left: 2%;
}
//
.demo-tabs>.el-tabs__content {
  padding: 32px;
  color: #00428d;
  font-size: 32px;
  font-weight: 600;
}

.demo-tabs .custom-tabs-label .el-icon {
  vertical-align: middle;
  color: #00428d;
}

.demo-tabs .custom-tabs-label span {
  vertical-align: middle;
  margin-left: 4px;
  color: #00428d;
}

::v-deep .el-tabs__item {
  color: green;
  opacity: 0.5;
}

.news-header {
  width: 100%;
  height: 300px;
  background-size: cover;
}

* {
  font-family: "OPPO Sans";
}

.company {
  width: 80%;
}

.company img {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0%);
}

//
.company-pic {
  width: 100%;
  padding-left: 40%;
}

.scd_r {
  width: 75%;
  margin: 0 auto;
}

.scd_r .r_title {
  width: 100%;
  text-align: center;
  padding-top: 55px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ccc;
  font-size: 30px;
  color: #000000;
}

.scd_r .r_title span {
  color: #999999;
}

.scd_r .r_title span em {
  font-size: 18px;
}

.scd_r .scd_m {
  width: 100%;
  padding: 28px 0;
  font-size: 16px;
  color: #606060;
  line-height: 255%;
}

.scd {
  width: 1200px;
  margin: 0px auto;
  padding-bottom: 20px;
}

.scd .scd_l {
  width: 220px;
  height: 700px;
  float: left;
  background: url(../assets/picture/bg_a1.png) no-repeat;
}

.scd_l .name {
  width: 100%;
  text-align: center;
  padding: 30px 0;
}

.scd_l .s_nav {
  width: 100%;
  text-align: left;
}

.scd_l .s_nav li {
  width: 190px;
  height: 42px;
  background: url(../assets/picture/line1.jpg) no-repeat left bottom;
  float: right;
  margin-right: 1px;
}

.scd_l .s_nav li a {
  display: block;
  width: 190px;
  height: 40px;
  padding-right: 0px;
  text-align: left;
  line-height: 40px;
  font-size: 16px;
  color: #552F00;
}

.scd_l .s_nav li.now a,
.scd_l .s_nav li:hover a {
  background: #00428d;
  color: #FFF;
}

.scd_l .s_nav li a span {
  display: inline-block;
  *display: inline;
  zoom: 1;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  background: url(../assets/picture/icon3.png) no-repeat left center;
}

.scd_l .s_nav li.now a span,
.scd_l .s_nav li:hover a span {
  color: #FFF;
  background: url(../assets/picture/icon2.png) no-repeat left center;
}

.scd_m p {
  text-indent: 2em;

}

.recruitment h4{
  color: #00428d;
  padding-top: 20px;
}
</style>