<link rel="stylesheet" type="text/css" href="Assets/css/thems.css"></link>
<template>
  <div>
    <div class="news-header" :style="{ backgroundImage: `url(${require('@/assets/images/about-us.jpg')})` }">
    </div>
    <!-- <div class="Breadcrumb">
            <el-breadcrumb :separator-icon="ArrowRight">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>关于我们</el-breadcrumb-item>
                 <el-breadcrumb-item>promotion list</el-breadcrumb-item>
            <el-breadcrumb-item>promotion detail</el-breadcrumb-item> 
            </el-breadcrumb>
            <el-divider style="margin-top: 10px;"/>
        </div> -->
    <div>
      <div>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
          @select="handleSelect" :router="true">
          <!-- <el-menu-item class="el-icon-location">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-menu-item> -->
          <el-menu-item disabled="true"></el-menu-item>
          <el-menu-item index="/companyprofile" class="menu">公司简介</el-menu-item>
          <el-menu-item index="/corporateculture" class="menu">企业文化</el-menu-item>
          <el-menu-item index="/qualification" class="menu">企业资质</el-menu-item>
          <el-menu-item index="/honors" class="menu">公司荣誉</el-menu-item>
          <el-menu-item index="/videos" class="menu1">视频中心</el-menu-item>

        </el-menu>
        <div class="Breadcrumbbround">
          <div class="Breadcrumb">
            <el-breadcrumb :separator-icon="ArrowRight">
              <el-breadcrumb-item :to="{ path: '/' }">
                <el-icon style="color: #00428d;font-size: larger;">
                  <HomeFilled />
                </el-icon>
              </el-breadcrumb-item>
              <el-breadcrumb-item>关于我们</el-breadcrumb-item>
              <el-breadcrumb-item>视频中心</el-breadcrumb-item>
            </el-breadcrumb>
          </div>

        </div>
        <div class="scd_r">
          <el-row>&nbsp;</el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card :body-style="{ padding: '0px'}" s hadow="hover">
                <div class="image">
                  <img src="../assets/picture/巾帼风采.jpg" width="100%" alt="" @click="video7" />
                </div>
                <div style="text-align: center;margin-top: -5px;height: 40px;margin-top: 10px;">
                  <a @click="video7">
                    <span>
                      <VideoCamera style="color: #00428d;width: 1em; height: 1em; margin-right: 8px" />巾帼风采
                    </span>
                  </a>

                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card :body-style="{ padding: '0px'}" s hadow="hover">
                <div class="image">
                  <img src="../assets/picture/党的二十大精神创新理论我来讲系列之一.jpg" width="100%" alt="" @click="video6" />
                </div>
                <div style="text-align: center;margin-top: -5px;height: 40px;margin-top: 10px;">
                  <a @click="video6">
                    <VideoCamera style="color: #00428d;width: 1em; height: 1em; margin-right: 8px" />
                    <span>党的二十大精神创新理论我来讲</span>
                  </a>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card :body-style="{ padding: '0px'}" s hadow="hover">
                <div class="image">
                  <img src="../assets/picture/新物流配送体系建设记.jpg" width="100%" alt="" @click="video5" />
                </div>
                <div style="text-align: center;margin-top: -5px;height: 40px;margin-top: 10px;">
                  <a @click="video5">
                    <span>
                      <VideoCamera style="color: #00428d;width: 1em; height: 1em; margin-right: 8px" />新物流配送体系建设记
                    </span>
                  </a>

                </div>
              </el-card>
            </el-col>
          </el-row>
          <el-row>&nbsp;</el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card :body-style="{ padding: '0px'}" s hadow="hover">
                <div class="image">
                  <img src="../assets/picture/以梦为马-不负韶华.jpg" width="100%" alt="" @click="video4" />
                </div>
                <div style="text-align: center;margin-top: -5px;height: 40px;margin-top: 10px;">
                  <a @click="video4">

                    <span>
                      <VideoCamera style="color: #00428d;width: 1em; height: 1em; margin-right: 8px" />以梦为马 不负韶华
                    </span>
                  </a>

                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card :body-style="{ padding: '0px'}" s hadow="hover">
                <div class="image">
                  <img src="../assets/picture/你笑起来真好看.jpg" width="100%" alt="" @click="video3" />
                </div>
                <div style="text-align: center;margin-top: -5px;height: 40px;margin-top: 10px;">
                  <a @click="video3">
                    <span>
                      <VideoCamera style="color: #00428d;width: 1em; height: 1em; margin-right: 8px" />你笑起来真好看
                    </span>
                  </a>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card :body-style="{ padding: '0px'}" s hadow="hover">
                <div class="image">
                  <img src="../assets/picture/我们的精益办.jpg" width="100%" alt="" @click="video2" />
                </div>
                <div style="text-align: center;margin-top: -5px;height: 40px;margin-top: 10px;">
                  <a @click="Video2">
                    <span>
                      <VideoCamera style="color: #00428d;width: 1em; height: 1em; margin-right: 8px" />我们的精益办
                    </span>
                  </a>

                </div>
              </el-card>
            </el-col>
          </el-row>
          <el-row>&nbsp;</el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card :body-style="{ padding: '0px'}" s hadow="hover">
                <div class="image">
                  <img src="../assets/picture/不忘初心的昆船人.jpg" width="100%" alt="" @click="video1" />
                </div>
                <div style="text-align: center;margin-top: -5px;height: 40px;margin-top: 10px;">
                  <a @click="video1">

                    <span>
                      <VideoCamera style="color: #00428d;width: 1em; height: 1em; margin-right: 8px" />不忘初心的昆船人
                    </span>
                  </a>

                </div>
              </el-card>
            </el-col>
          </el-row>

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { ArrowRight,Calendar,HomeFilled,VideoCamera } from '@element-plus/icons-vue';
import {useRouter} from 'vue-router'
const router = useRouter()
// const newsgateToProfile=()=>{
//   router.push(`/companyprofile`)
// }
// const newsgateToCulture=()=>{
//   router.push(`/corporateculture`)
// }
// const newsgateToQualification=()=>{
//   router.push(`/qualification`)
// }
// const newsgateToHonors=()=>{
//   router.push(`/Honors`)
// }
const video1=()=>{
  router.push(`/video1`)
}
const video2=()=>{
  router.push(`/video2`)
}
const video3=()=>{
  router.push(`/video3`)
}
const video4=()=>{
  router.push(`/video4`)
}
const video5=()=>{
  router.push(`/video5`)
}
const video6=()=>{
  router.push(`/video6`)
}
const video7=()=>{
  router.push(`/video7`)
}
</script>

<style scoped lang="scss">
//子菜单
.el-menu{
  border-radius: 5px!important;
  border-block-color: #f4f4f4;
  height: 80px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.menu {
  width: 190px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  position: relative; /* 为了使::after伪元素正确显示 */
}
.menu::after {
  content: '';
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%; /* 边框宽度可以根据需要调整 */
  height: 20%; /* 高度与容器相同 */
  border-right: 2px solid rgb(222, 222, 222); /* 边框样式，可以根据需要调整 */
}
.menu1{
  width: 190px;
  background-image: linear-gradient(to bottom, #00428d, #00428d);
  color: #fff !important;
}
.el-submenu__title:hover,
.el-submenu.is-active,
.el-menu-item.is-active {
    background-image: linear-gradient(to bottom, #00428d, #00428d);
    background-color: transparent;
    border-bottom-color: #00428d !important;
    border-bottom: 2px solid #00428d;
    color: #fff !important;
}
.el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
      background-image: linear-gradient(to bottom, #00428d, #00428d);
      border-bottom-color: #00428d !important;
      color: #fff !important;
    }

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 0%;
}
//面包屑
.Breadcrumbbround{
    padding-top: 20px;
    height: 35px;
    margin: 0 auto;
    width: 80%;
    background-color: #f7f7f7;
    margin-top: 30px;
    border-radius: 50px / 50px; /* 水平半径和垂直半径 */
}
.Breadcrumb{
    
    margin-left: 2%;
}
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #00428d;
  font-size: 32px;
  font-weight: 600;
}
.demo-tabs .custom-tabs-label .el-icon {
  vertical-align: middle;
  color: #00428d;
}
.demo-tabs .custom-tabs-label span {
  vertical-align: middle;
  margin-left: 4px;
  color: #00428d;
}
::v-deep .el-tabs__item {
    color:green;
    opacity: 0.5;
}
.news-header {
    width: 100%;
    height: 300px;
    background-size: cover;
}

* {
  font-family: "OPPO Sans";
  }
  .company{
    width: 80%;  
  }
  
  .company img{
	position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%,0%);
}

//
.company-pic{   
    width: 100%;
    padding-left: 40%;
}

.scd_r {
    width: 75%;
    margin: 0 auto;
}
.scd {
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 20px;
}
.scd .scd_l{
	width: 220px;
	height: 700px;
	float: left;
	background: url(../assets/picture/bg_a1.png) no-repeat;
}

.scd_l .name{ width:100%; text-align:center; padding:30px 0;}
.scd_l .s_nav{
	width: 100%;
	text-align: left;
}
.scd_l .s_nav li{
	width: 190px;
	height: 42px;
	background: url(../assets/picture/line1.jpg) no-repeat left bottom;
	float: right;
	margin-right: 1px;
}
.scd_l .s_nav li a{
	display: block;
	width: 190px;
	height: 40px;
	padding-right: 0px;
	text-align: left;
	line-height: 40px;
	font-size: 16px;
	color: #552F00;
}
.scd_l .s_nav li.now a,
.scd_l .s_nav li:hover a {
    background: #00428d;
    color: #FFF;
}

.scd_l .s_nav li a span {
    display: inline-block;
    *display: inline;
    zoom: 1;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    background: url(../assets/picture/icon3.png) no-repeat left center;
}

.scd_l .s_nav li.now a span,
.scd_l .s_nav li:hover a span {
    color: #FFF;
    background: url(../assets/picture/icon2.png) no-repeat left center;
}
.scd_m p{
    text-indent: 2em;

}
.image{
      width: 100%;
      height: 200px;
      background-size: cover;
  }
</style>