
<!-- <script type="text/javascript" src="//api.map.baidu.com/api?type=webgl&v=1.0&ak=3jFqlYOIlcRIUc3YoCVdziNtMOFXcFn5"></script> -->
<template>
    <div>
        <div class="news-header" :style="{ backgroundImage: `url(${require('@/assets/images/contact-us.jpg')})` }">
        </div>
    </div>
    <div>
        <div>

            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
            @select="handleSelect" :router="true">
                <!-- <el-menu-item class="el-icon-location">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-menu-item> -->
                <el-menu-item disabled="true"></el-menu-item>
                <el-menu-item index="/contact" class="menu">联系方式</el-menu-item>
                <el-menu-item index="/Massage" class="menu1">在线留言</el-menu-item>

            </el-menu>
            <div>
                <div>&nbsp;</div>
                <div class="Breadcrumbbround">
                    <div class="Breadcrumb">
                        <el-breadcrumb :separator-icon="ArrowRight">
                            <el-breadcrumb-item :to="{ path: '/' }">
                                <el-icon style="color: #00428d;font-size: larger;">
                                    <HomeFilled />
                                </el-icon>
                            </el-breadcrumb-item>
                            <el-breadcrumb-item>在线留言</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>

                </div>


                <div style="margin-left: 15%;padding-bottom: 60px;">
                    <!-- <div class="r_title">
              企业文化<span>/<em>Corporate Culture</em></span>
            </div> -->
                    <!-- <p style="text-align: center;">敬请期待......</p> -->
                    <el-card style="width: 80%;margin-top: 40px;">
                        <el-form ref="massageFormRef" :model="massageForm" :rules="umassageFormRules" label-width="80px"
                            class="demo-ruleForm" :inline="true">
                            <el-form-item label="姓名" prop="name">
                                <el-input v-model="massageForm.name" />
                            </el-form-item>
                            <el-form-item label="电话" prop="phone">
                                <el-input v-model="massageForm.phone" style="width: 230px;" />
                            </el-form-item>
                            <el-form-item label="邮箱" prop="email">
                                <el-input v-model="massageForm.email" style="width: 880px;" />
                            </el-form-item>
                            <el-form-item label="内容" prop="content">
                                <el-input v-model="massageForm.content" type="textarea" style="width: 880px;"
                                    :autosize="{ minRows: 13, maxRows: 15 }" />
                            </el-form-item>
                            <!-- <el-form-item label="类别" prop="category">
                <el-select v-model="capacitiesForm.category" class="m-2" placeholder="Select" style="width:100%">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>  
            </el-form-item> -->
                            <!-- <el-form-item label="内容" prop="content">
                <editor @event="handleChange" />
            </el-form-item> -->
                            <!-- <el-form-item label="作者" prop="author">
                <el-input v-model="capacitiesForm.author" />
            </el-form-item> -->

                            <!-- <el-form-item
                label="封面"
                prop="cover"
            >
                <Upload
                    :avatar="capacitiesForm.cover"
                    @kerwinchange="handleUploadChange"
                />
            </el-form-item> -->
                            <el-form-item>
                                <el-button color="#00428d" @click="submitForm()" style="width: 880px;margin-left: 17%;">提交</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>


                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import { Location,Phone,HomeFilled,ArrowRight  } from '@element-plus/icons-vue'
import {useRoute} from 'vue-router'
import {ref,reactive} from 'vue'
import { ElMessage } from "element-plus";
// import editor from '@/components/editor/Editor.vue'//引入富文本编辑器

import upload from '@/store/upload'
const route = useRoute()
// const router = useRouter()
const massageFormRef = ref()
const massageForm = reactive({
    name:"",//姓名
    phone:"",//手机
    email:"",//邮箱
    content:"",//内容
    file:null,

});
const umassageFormRules = reactive({
    name:[
        {required:true,message:"请输入姓名",trigger:"blur"}
    ],
    phone:[
        {required:true,message:"请输入电话号码",trigger:"blur"}
    ],
    content:[
        {required:true,message:"请输入内容",trigger:"blur"}
    ],


})
const handleClick = ()=>{
    window.location = "http://localhost:8080"
}
const handleUploadChange = (file)=>{
    massageForm.cover = URL.createObjectURL(file);
    massageForm.file = file
}
const submitForm = () => {
    massageFormRef.value.validate(async (valid) => {
        if (valid) {
            //console.log(massageForm)
            //后台通信,
            massageForm.reply="1";
            const res =await upload("/webapi/massage/add", massageForm)
            // route.push(`/capacities-manage/capacitiesList`)
            if (res.ActionType === "OK") {
                ElMessage.success("提交成功");
                massageForm.name="",
                massageForm.phone="",
                massageForm.email="",
                massageForm.content=""

            }

        }
    })
}

</script>
<style scoped lang="scss">
// .demo-ruleForm .el-input {
//   --el-input-width: 220px;
// }
.el-form{
    margin-top: 50px;
    width: 75%;
    
}
//子菜单
.el-menu{
  border-radius: 5px!important;
  border-block-color: #f4f4f4;
  height: 80px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.menu {
  width: 190px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  position: relative; /* 为了使::after伪元素正确显示 */
}
.menu1{
  width: 190px;
  background-image: linear-gradient(to bottom, #00428d, #00428d);
  color: #fff !important;
}
.el-submenu__title:hover,
.el-submenu.is-active,
.el-menu-item.is-active {
    background-image: linear-gradient(to bottom, #00428d, #00428d);
    background-color: transparent;
    border-bottom-color: #00428d !important;
    border-bottom: 2px solid #00428d;
    color: #fff !important;
}
.el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
      background-image: linear-gradient(to bottom, #00428d, #00428d);
      border-bottom-color: #00428d !important;
      color: #fff !important;
    }

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 0%;
}
.news-header {
    width: 100%;
    height: 300px;
    background-size: cover;
}
//字体
* {
  font-family: "OPPO Sans";
  }
  //
.company-pic{   
    width: 100%;
    padding-left: 40%;
}
.scd .scd_r {
    width: 945px;
    float: right;
}

.scd_r .r_title {
    width: 100%;
    text-align: center;
    padding-top: 55px;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #ccc;
    font-size: 30px;
    color: #000000;
}

.scd_r .r_title span {
    color: #999999;
}

.scd_r .r_title span em {
    font-size: 18px;
}

.scd_r .scd_m {
    width: 100%;
    padding: 28px 0;
    font-size: 14px;
    color: #999999;
    line-height: 215%;
}

.scd {
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 20px;
}
.scd .scd_l{
	width: 220px;
	height: 700px;
	float: left;
	background: url(../assets/picture/bg_a1.png) no-repeat;
}

.scd_l .name{ width:100%; text-align:center; padding:30px 0;}
.scd_l .s_nav{
	width: 100%;
	text-align: left;
}
.scd_l .s_nav li{
	width: 190px;
	height: 42px;
	background: url(../assets/picture/line1.jpg) no-repeat left bottom;
	float: right;
	margin-right: 1px;
}
.scd_l .s_nav li a{
	display: block;
	width: 190px;
	height: 40px;
	padding-right: 0px;
	text-align: left;
	line-height: 40px;
	font-size: 16px;
	color: #552F00;
}
.scd_l .s_nav li.now a,
.scd_l .s_nav li:hover a {
    background: #00428d;
    color: #FFF;
}

.scd_l .s_nav li a span {
    display: inline-block;
    *display: inline;
    zoom: 1;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    background: url(../assets/picture/icon3.png) no-repeat left center;
}

.scd_l .s_nav li.now a span,
.scd_l .s_nav li:hover a span {
    color: #FFF;
    background: url(../assets/picture/icon2.png) no-repeat left center;
}
.p{
    text-indent: 2em;
}
.biaoti{
    padding-top: 20px;
    color: #00428d;
    font-size: x-large;
}
//面包屑
.Breadcrumbbround{
    padding-top: 20px;
    height: 35px;
    margin: 0 auto;
    width: 80%;
    background-color: #f7f7f7;
    // margin-top: 30px;
    border-radius: 50px / 50px; /* 水平半径和垂直半径 */
}
.Breadcrumb{
    
    margin-left: 2%;
}
//
.tagline{
    margin-left: 12%;
}
</style>