<link rel="stylesheet" type="text/css" href="Assets/css/thems.css">
</link>
<template>
  <div>
    <div class="news-header" :style="{ backgroundImage: `url(${require('@/assets/images/about-us.jpg')})` }">
    </div>
    <div>
      <div>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
          @select="handleSelect" :router="true">
          <!-- <el-menu-item class="el-icon-location">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-menu-item> -->
          <el-menu-item disabled="true"></el-menu-item>
          <el-menu-item index="/companyprofile" class="menu1">公司简介</el-menu-item>
          <el-menu-item index="/corporateculture" class="menu">企业文化</el-menu-item>
          <el-menu-item index="/qualification" class="menu">企业资质</el-menu-item>
          <el-menu-item index="/honors" class="menu">公司荣誉</el-menu-item>
          <el-menu-item index="/videos" class="menu">视频中心</el-menu-item>

        </el-menu>
        <div class="Breadcrumbbround">
                <div class="Breadcrumb">
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/' }">
                            <el-icon style="color: #00428d;font-size: larger;">
                                <HomeFilled />
                            </el-icon>
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>关于我们</el-breadcrumb-item>
                        <el-breadcrumb-item>公司简介</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

            </div>

        <div class="scd_r">
          <!-- <div class="r_title">
            公司简介<span>/<em>Company profile</em></span>
          </div> -->
          <div class="scd_m">
            <div class="about clearfix">
              <img src="../assets/images/company.jpg" alt="" style="float:left; margin-right:20px;" width="370"
                height="230" />
              <p>
                云南昆船机械制造有限公司（以下简称公司）始建于1969年，前身为云南昆船第一机械有限公司，2017年更名为云南昆船机械制造有限公司。公司隶属于昆明船舶设备集团有限公司，注册资本4.7亿元，现有资产近7.8亿元，生产厂房建筑面积8万余平方米。是集光、机、电、信息为一体的大型国有高新技术骨干制造企业。公司装备实力雄厚，设备类型齐全，技术工艺能力基础坚实，具有完整、较强的精密机械加工、复杂钣金成形加工、大口径薄壁筒体有色金属精密铸造、先进的表面防腐处理工艺和热处理工艺、特种焊接、材料理化检验分析和产品检测试验验证能力。公司具有较强的开拓创新意识和技术创新能力，拥有较为完善的技术创新体系、较强的研发能力，形成了一批具有自主知识产权的科技成果。
              </p>
              <p>
                公司全面实施了SAP-ERP企业资源管理平台、OA自动化办公平台，建立了PDM、CAD、CAE、CAPP等研发设计系统，部署了DNC、MDC等车间数字化管理系统，实现了产品设计、工艺设计、生产制造数字化、网络化，为快速响应市场需求和提供高效、高质量生产服务奠定了基础。
              </p>
              <p>公司通过了ISO9001/GB/T9001B、GJB 9001C-2017质量体系认证；取得了D1/D2级压力容器生产许可、停车设备制造许可和三级计量检测资质证的认定。</p>
              <p>
                公司拥有一支专业齐全、技术能力强、经验丰富的职工队伍，具备设计、制造、检验、安调、售后服务能力的人才队伍和较强的开展数字化设计、生产、检测、试验、服务的系统和技术装备，形成了满足产品全生命周期的服务能力。
              </p>
              <p>
                公司构建以两化融合为核心的综合管控体系及全面风险管理体系，持续开展管理改进提升活动，夯实管理基础，提高管理水平。公司持续加强管理创新及技术创新，深入开展提质增效，经济运行质量明显提升。经过多年的努力，公司的经济实力和综合竞争能力不断增强，巩固及提升了公司制造生产的骨干地位，满足了公司持续发展的战略需要。
              </p>
              <p>&nbsp;</p>
              <!--<table width="160" border="0">
                <tr>
                  <img src="Assets/images/zz.jpg" width="150"  >
                  </tr>
                </table>
              <p>&nbsp;</p>
              <p><img src="Assets/images/企业架构图.png" width="1000" ></p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { ArrowRight, Calendar, HomeFilled } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router'
const router = useRouter()

</script>

<style scoped lang="scss">
//子菜单
.el-menu{
  border-radius: 5px!important;
  border-block-color: #f4f4f4;
  height: 80px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.menu {
  width: 190px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  position: relative; /* 为了使::after伪元素正确显示 */
}

.menu::after {
  content: '';
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  height: 20%;
  border-right: 2px solid rgb(222, 222, 222);
}

.menu1 {
  width: 190px;
  background-image: linear-gradient(to bottom, #00428d, #00428d);
  color: #fff !important;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.el-submenu__title:hover,
.el-submenu.is-active,
.el-menu-item.is-active {
    background-image: linear-gradient(to bottom, #00428d, #00428d);
    background-color: transparent;
    border-bottom-color: #00428d !important;
    border-bottom: 2px solid #00428d;
    color: #fff !important;
}
.el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
      background-image: linear-gradient(to bottom, #00428d, #00428d);
      border-bottom-color: #00428d !important;
      color: #fff !important;
    }

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 0%;
}
//面包屑
.Breadcrumbbround{
    padding-top: 20px;
    height: 35px;
    margin: 0 auto;
    width: 80%;
    background-color: #f7f7f7;
    margin-top: 30px;
    border-radius: 50px / 50px; /* 水平半径和垂直半径 */
}
.Breadcrumb{
    
    margin-left: 2%;
}
//
.demo-tabs>.el-tabs__content {
  padding: 32px;
  color: #00428d;
  font-size: 32px;
  font-weight: 600;
}

.demo-tabs .custom-tabs-label .el-icon {
  vertical-align: middle;
  color: #00428d;
}

.demo-tabs .custom-tabs-label span {
  vertical-align: middle;
  margin-left: 4px;
  color: #00428d;
}

::v-deep .el-tabs__item {
  color: green;
  opacity: 0.5;
}

.news-header {
  width: 100%;
  height: 300px;
  background-size: cover;
}

* {
  font-family: "OPPO Sans";
}

.company {
  width: 80%;
}

.company img {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0%);
}

//
.company-pic {
  width: 100%;
  padding-left: 40%;
}

.scd_r {
  width: 75%;
  margin: 0 auto;
}

.scd_r .r_title {
  width: 100%;
  text-align: center;
  padding-top: 55px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ccc;
  font-size: 30px;
  color: #000000;
}

.scd_r .r_title span {
  color: #999999;
}

.scd_r .r_title span em {
  font-size: 18px;
}

.scd_r .scd_m {
  width: 100%;
  padding: 28px 0;
  font-size: 16px;
  color: #606060;
  line-height: 255%;
}

.scd {
  width: 1200px;
  margin: 0px auto;
  padding-bottom: 20px;
}

.scd .scd_l {
  width: 220px;
  height: 700px;
  float: left;
  background: url(../assets/picture/bg_a1.png) no-repeat;
}

.scd_l .name {
  width: 100%;
  text-align: center;
  padding: 30px 0;
}

.scd_l .s_nav {
  width: 100%;
  text-align: left;
}

.scd_l .s_nav li {
  width: 190px;
  height: 42px;
  background: url(../assets/picture/line1.jpg) no-repeat left bottom;
  float: right;
  margin-right: 1px;
}

.scd_l .s_nav li a {
  display: block;
  width: 190px;
  height: 40px;
  padding-right: 0px;
  text-align: left;
  line-height: 40px;
  font-size: 16px;
  color: #552F00;
}

.scd_l .s_nav li.now a,
.scd_l .s_nav li:hover a {
  background: #00428d;
  color: #FFF;
}

.scd_l .s_nav li a span {
  display: inline-block;
  *display: inline;
  zoom: 1;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  background: url(../assets/picture/icon3.png) no-repeat left center;
}

.scd_l .s_nav li.now a span,
.scd_l .s_nav li:hover a span {
  color: #FFF;
  background: url(../assets/picture/icon2.png) no-repeat left center;
}

.scd_m p {
  text-indent: 2em;

}
</style>